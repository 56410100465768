@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 1.1428571428571428rem;
  font-family: Open Sans, sans-serif;
  /* font-weight: 400; */

  line-height: 1.5;
  /* background-color: #141b2d; */
}

.app {
  display: flex;
  position: relative;
}

/* ::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

p {
  margin: 20px 0;
}
button {
  border: 0;
  padding: 8px;
  border-radius: 4px;
  color: white;
  background: #4f6d7a;
  cursor: pointer;
}

/* navbar */
header nav {
  display: flex;
  padding: 20px;
  gap: 16px;
  flex: end;
  margin: 0 auto;
  background: hsl(198, 21%, 39%);
  /* background: #1db5f5; */
}
header nav h1 {
  margin-right: auto;
}
header nav a {
  text-decoration: none;
  border-radius: 4px;
}
header nav a.active {
  background: #c0d6df;
  color: #4f6d7a !important;
}

/* page content */

nav a {
  /* border: 5px solid white; */
  padding: 10px;
  /* border-radius: 4px; */
  text-decoration: none;
  color: whitesmoke !important;
}
nav a:hover {
  border-color: #e8dab2;
}
nav a:active {
  border-color: #dd6e42;
}

.login-button {
  border: 5px solid #1db5f5;
  margin-right: 60px;
  margin: 20px 0px 20px 20px;
  background: #c0d6df;
  border-radius: 4px;
}

.profile-margin {
  margin-top: 20px;
}

footer {
  background: #4f6d7a;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
}

footer p {
  background: #4f6d7a;
}

h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.carouselNone {
  position:static !important;
}
.carousel-caption-none{
  position:static !important;
}